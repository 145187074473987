.logins .loginBg {
    background: url("../img/bg.jpg");
    background-size: cover;
    position: relative;
    background-position-y: top;

}
.logins .icon {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}

.logins .icon img {
    width: 40%;
}


.logins .contentLogin .orline {
    text-align: center;
    border-bottom: 1px solid #e8e8e8;
    line-height: 0.1em;
    margin: 30px 0 30px;
}

.logins .contentLogin .orline span {
    background: #fff;
    padding: 0 10px;
    color: #999;
}

.logins .contentLogin .forgetPassword {
    

    text-align: right;
    font-size: 12px;
    padding-top: 6px;
    margin-bottom: 15px;
}

.logins .btn-custom {
    color: #fff !important;
    background-color: #41009a !important;
    border-color: #41009a !important;
    
}
